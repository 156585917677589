<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-one/banner/shape1.png" alt="Shape">
        <img src="assets/img/home-one/banner/shape2.png" alt="Shape">
        <img src="assets/img/home-one/banner/shape3.png" alt="Shape">
        <img src="assets/img/home-one/banner/banner-shape.png" alt="Shape">
    </div>

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Get Spiz Food by Ordering Online</h1>
                            <p>A restaurant sometimes known as a diner is a place where cooked food is sold to the public, and where people sit down to eat it. It is also a place where people go to enjoy the time and to eat a meal.</p>
                            <form>
                                <input type="text" class="form-control" placeholder="Enter food name">
                                <button type="submit" class="btn banner-form-btn">Search Now</button>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-slider owl-theme owl-carousel">
                            <div class="slider-item">
                                <img src="assets/img/home-one/banner/banner-slider1.png" alt="Slider">
                            </div>

                            <div class="slider-item">
                                <img src="assets/img/home-one/banner/banner-slider2.png" alt="Slider">
                            </div>

                            <div class="slider-item">
                                <img src="assets/img/home-one/banner/banner-slider3.png" alt="Slider">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="feature-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Featured Categories</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature1.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature1.png" alt="Feature"></li>
                            <li><span>Fast Food</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature2.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature2.png" alt="Feature"></li>
                            <li><span>Hot Platter</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature3.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature3.png" alt="Feature"></li>
                            <li><span>Dessert</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Spiz Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="services-slider owl-theme owl-carousel">
            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services1.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Fresh Healthy Food</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services2.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Free Fast Home Delivery</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Discount Voucher</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Discount Voucher</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>
        </div>
    </div>
</section>

<div class="restant-area">
    <div class="restant-shape"><img src="assets/img/home-one/services-shape2.png" alt="Shape"></div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="restant-img">
                    <img src="assets/img/home-one/restant.png" alt="Restant">
                    <img src="assets/img/home-one/restant2.png" alt="Restant">
                    <img src="assets/img/home-one/restant3.png" alt="Restant">
                    <img src="assets/img/home-one/restant4.png" alt="Restant">
                    <img src="assets/img/home-one/restant5.png" alt="Restant">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="restant-content">
                    <div class="section-title">
                        <h2>Spiz is One Of The Most Hygienic & Trusted Food Service</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby.</p>
                    </div>
                    <a class="cmn-btn" routerLink="/services-details">Know More</a>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="collection-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Regular Food Collections</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="sorting-menu">
            <ul> 
               <li class="filter active" data-filter="all">All</li>
               <li class="filter" data-filter=".web">Fast Food</li>
               <li class="filter" data-filter=".ui">Platters</li>
               <li class="filter" data-filter=".ux">Dessert</li>
               <li class="filter" data-filter=".branding">Asian Food</li>
            </ul>
        </div>

        <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix web ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/1.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>

                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$25</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/2.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Cake with Drinks</h3>
                        <ul>
                            <li><span>$15</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/3.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Doughnut Chocolate</h3>
                        <ul>
                            <li><span>$20</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding web">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/4.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$23</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web branding">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/5.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Sweet Dougnuts</h3>
                        <ul>
                            <li><span>$35</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui web">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/6.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Birthday Cake</h3>
                        <ul>
                            <li><span>$32</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux branding">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/7.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Chocolate Ice Cream</h3>
                        <ul>
                            <li><span>$28</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/8.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$27</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="more-collection">
            <a routerLink="/food-collection">View More Colletction</a>
        </div>
    </div>
</section>

<section class="menu-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Explore Our Food Menu</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="menu-item">
                    <img class="menu-shape" src="assets/img/home-one/services-shape.png" alt="Menu">
                    <img src="assets/img/home-one/menu1.png" alt="Menu">
                    <h3>Breakfast Item</h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="menu-item active">
                    <img class="menu-shape" src="assets/img/home-one/services-shape.png" alt="Menu">
                    <img src="assets/img/home-one/menu2.png" alt="Menu">
                    <h3>Lunch Item</h3>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="menu-item">
                    <img class="menu-shape" src="assets/img/home-one/services-shape.png" alt="Menu">
                    <img src="assets/img/home-one/menu3.png" alt="Menu">
                    <h3>Dinner Item</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="reservation-area">
    <div class="reservation-shape"><img src="assets/img/home-one/reservation-shape.png" alt="Shape"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="reservation-item">
                    <div class="section-title">
                        <h2>Reservation A Table</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    </div>
                    <form>
                        <ul>
                            <li>
                                <input type="date" class="form-control" name="arrive">
                            </li>
                            <li>
                                <input type="time" class="form-control" name="arrive">
                            </li>
                            <li>
                                <button type="submit" class="btn cmn-btn">Reserve Now</button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="reservation-img">
                    <img src="assets/img/home-one/reservation.png" alt="Reservation">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="chef-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Special Chefs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/1.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Doe</h3>
                            <span>Head of Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/6.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Smith</h3>
                            <span>Assistant Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item active">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/3.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Evanaa</h3>
                            <span>Intern Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/7.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Knot Doe</h3>
                            <span>Asst. Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="review-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0">
                <div class="review-img">
                    <img src="assets/img/home-one/review1.png" alt="Review">
                    <img src="assets/img/home-one/review2.png" alt="Review">
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="review-item">
                    <div class="section-title">
                        <h2>What People Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="slider-nav">
                        <div class="item">
                            <img src="assets/img/home-one/review3.png" alt="image"  draggable="false"/>
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review4.png" alt="image" draggable="false"/>
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review5.png" alt="image" draggable="false"/>
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review6.png" alt="image" draggable="false"/>
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review7.png" alt="image" draggable="false"/>
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review8.png" alt="image" draggable="false"/>
                        </div>
                    </div>

                    <div class="slider-for">
                        <div class="item">
                            <h3>John Doe</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Jac Jackson</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Tom Henry</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>John Mic</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Stark Arey</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Stark Arey</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-detailhtml">
                            <img src="assets/img/home-one/blog1.jpg" alt="Blog">
                        </a>
                        <span>01 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-detailhtml">Brief About How to Make Pasta</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-detailhtml">
                            <img src="assets/img/home-one/blog2.jpg" alt="Blog">
                        </a>
                        <span>02 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-detailhtml">Brief About How to Make Pizza</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-detailhtml">
                            <img src="assets/img/home-one/blog3.jpg" alt="Blog">
                        </a>
                        <span>03 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-detailhtml">Brief About How to Make Burger</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <a class="read-blog-btn" routerLink="/blog">Read More Blogs</a>
        </div>
    </div>
</section>

<section class="subscribe-area">
    <div class="subscribe-shape"><img src="assets/img/home-one/reservation-shape.png" alt="Shape"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="subscribe-item">
                    <div class="section-title">
                        <h2>Subscribe News Letter for Get Update</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <form class="newsletter-form" data-toggle="validator">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        <button class="btn cmn-btn" type="submit">Subscribe</button>
                    </form>

                    <div class="social-link">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-5">
                <div class="subscribe-img">
                    <img src="assets/img/home-one/subscribe-main.png" alt="Subscribe">
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>