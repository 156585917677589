import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KafarmamConts } from '../../../../assets/kafarmam-contants.consts';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  constructor(
    private rout: ActivatedRoute,
    private route: Router,
  ) { }

  services = KafarmamConts.SERVICES
  service: any;

  ngOnInit(): void {
    const id = +this.rout.snapshot.paramMap.get('id');
    this.service = this.services.find((ser) => ser.Id == id)
  }

  GetById(id: number) {
    // this.route.navigate(['/services-details', id]);
    this.service = this.services.find((ser) => ser.Id == id)
  }

}
