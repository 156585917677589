import { Component, OnInit } from '@angular/core';
import { LambdaService } from '../../../../app/services/lambda/lambda.service';
import { SendEmailInputDto } from '../../../../app/services/lambda/dtos/send-email-input.dto';
import { NgForm } from '@angular/forms';
import { KafarmamConts } from '../../../../assets/kafarmam-contants.consts';
import { ToastrNotificationService } from '../../../../app/services/toaster-notification/toastr-notification.service';
import { SubscriberInputDto } from 'src/app/services/lambda/dtos/subscriber-input.dto';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(
    private lambdaService: LambdaService,
    private toastr: ToastrNotificationService
  ) { }

  emailData = {} as SendEmailInputDto;

  ngOnInit(): void {
  }

  instaLink = KafarmamConts.KAFARMAM_INSTAGRAM_LINK

  SendEmail(form: NgForm) {

    if (!this.emailData.Content || !this.emailData.Name || !this.emailData.Source) {
      this.toastr.ShowError('Fill the required fields', null)
      return
    }

    // add a subscriber into dynamo db
    this.lambdaService.AddSubscriber(<SubscriberInputDto>{
      Email: this.emailData.Source,
      PhoneNumber: this.emailData.PhoneNumber,
      Name: this.emailData.Name
    }).subscribe((res) => {
    })

    //send contact us email
    this.lambdaService.SendEmail(<SendEmailInputDto>{
      Source: KafarmamConts.KAFARMAM_NOREPLY_EMAIL,
      Destination: [KafarmamConts.KAFARMAM_INFO_EMAIL],
      Subject: this.emailData.Subject + `<${this.emailData.Source}>`,
      Content: this.emailData.Content,
    })
      .subscribe((response) => {
        this.toastr.ShowSuccess('Message Sent', this.emailData.Subject)
        this.resetform(form);
        console.log(response)
      },
        (error) => {
          console.log(error)
        })

  }

  resetform(form: NgForm) {
    form.form.reset();
  }

}
