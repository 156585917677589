import { Component, OnInit } from '@angular/core';
import { KafarmamConts } from '../../../../assets/kafarmam-contants.consts';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor() { }

  services = KafarmamConts.SERVICES;

  ngOnInit(): void {
  }

}
