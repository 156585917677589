import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SendEmailInputDto } from './dtos/send-email-input.dto';
import { SubscriberInputDto } from './dtos/subscriber-input.dto';
import { v4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class LambdaService {

  constructor(
    private readonly httpService: HttpClient,
  ) {
  }

  SendEmail(input: SendEmailInputDto) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const params = {
      ToAddresses: input.Destination,
      Source: input.Source,
      Subject: input.Subject,
      Content: input.Content
    };

    return this.httpService.post('https://bpf0tme2oi.execute-api.eu-west-1.amazonaws.com/Release01/proxy', params, httpOptions)
  }

  AddSubscriber(input: SubscriberInputDto) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const params = {
      Id: v4(),
      Email: input.Email,
      Name: input.Name,
      PhoneNumber: input.PhoneNumber
    };

    return this.httpService.post('https://2pqfnnvonf.execute-api.eu-west-1.amazonaws.com/Stage01/proxy', params, httpOptions)
  }
}
