<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Food Collection</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Food Collection</li>
            </ul>
        </div>
    </div>
</div>

<section class="collection-area collection-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Regular Food Collections</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="sorting-menu">
            <ul> 
               <li class="filter active" data-filter="all">All</li>
               <li class="filter" data-filter=".web">Fast Food</li>
               <li class="filter" data-filter=".ui">Platters</li>
               <li class="filter" data-filter=".ux">Dessert</li>
               <li class="filter" data-filter=".branding">Asian Food</li>
            </ul>
        </div>

        <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix web ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/1.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>

                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$25</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/2.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Cake with Drinks</h3>
                        <ul>
                            <li><span>$15</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/3.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Doughnut Chocolate</h3>
                        <ul>
                            <li><span>$20</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding web">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/4.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$23</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web branding">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/5.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Sweet Dougnuts</h3>
                        <ul>
                            <li><span>$35</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui web">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/6.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Birthday Cake</h3>
                        <ul>
                            <li><span>$32</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux branding">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/7.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Chocolate Ice Cream</h3>
                        <ul>
                            <li><span>$28</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/8.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$27</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1"/>
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>