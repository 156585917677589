<div class="page-title-area page-title-img-three">
    <div class="container">
        <div class="page-title-item">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-location-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-time-five'></i>
                    <ul>
                        <li>9:00 AM to 6:00 PM</li>
                        <li>Customer Service</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="location-item active">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-location-plus'></i>
                    <ul>
                        <li>Kafarhamam, South, Lebanon</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-phone-call'></i>
                    <ul>
                        <li><a href="tel:+96176019214">+961 76 019 214</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contact-item">
                    <form novalidate #form="ngForm" (submit)="SendEmail(form)" id="contactForm">
                        <div class="row">
                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" [(ngModel)]="emailData.Name" placeholder="Name"
                                        required>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" [(ngModel)]="emailData.Source"
                                        class="form-control" placeholder="Email" required>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number"
                                        [(ngModel)]="emailData.PhoneNumber" required class="form-control"
                                        placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject"
                                        [(ngModel)]="emailData.Subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message"
                                        [(ngModel)]="emailData.Content" cols="30" rows="6" required
                                        placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <button type="submit" class="cmn-btn btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <div class="contact-social">
                        <span>Follow Us on</span>

                        <ul>
                            <!-- <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> -->
                            <li><a href={{instaLink}} target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <!-- <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-6">
                <div class="contact-img">
                    <img src="assets/img/contact-man.png" alt="Contact">
                </div>
            </div> -->
        </div>
    </div>
</div>