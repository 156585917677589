
<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let b of blog">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details/{{b.Id}}"><img src="{{b.ImageUrl}}" alt="Blog"></a>
                        <span>{{b.Date}}</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details/{{b.Id}}">{{b.Title}}</a></h3>
                        <p>{{b.Brief}}</p>
                        <a class="cmn-btn" [ngStyle]="{ 'bottom': b.Brief == null ? '-45px' : '-20px'}" routerLink="/blog-details/{{b.Id}}">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
