import { Component, HostListener, OnInit } from '@angular/core';
import { KafarmamConts } from 'src/assets/kafarmam-contants.consts';

@Component({
  selector: 'app-navbar-style-three',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {

  constructor() { }

  whiteColor = '#FFFFE9';
  phoneNumber = KafarmamConts.KAFARMAM_PHONE_NUMBER;

  ngOnInit(): void {

    window.addEventListener('scroll', function (e) {
      var nav = document.getElementById('nav');
      if (document.documentElement.scrollTop || document.body.scrollTop > window.innerHeight) {
        nav.classList.add('main-nav-scroll');
        nav.classList.remove('main-nav');
      } else {
        nav.classList.add('main-nav');
        nav.classList.remove('main-nav-scroll');
      }
    });
  }
}
