
export class KafarmamConts {
    static readonly SERVICES = [
        {
            Id: 1,
            Name: "Fresh Healthy Ingredients",
            Description:
                "Our additives are the key for a better meal and health as well. They are your forever secret weapon in your kitchen. No chemicals are added to prevent spoiling-from the source to your pantry. All these ingredients are planted, harvested and prepared with utmost love and care. You’ll start your day with a breakfast made up of labneh dipped in olive oil served with olives and za’atar. Throughout your day you’ll need an energizer, so you’ll get it from a healthy bag full of nuts and dried figs. Many more is awaiting you, just keep in touch.",
            Brief: "It’s all what your kitchen and appetite need to fulfill your cooking.",
            IconUrl: "assets/img/services-details/fresh-ingredients-icon.png",
        },
        {
            Id: 2,
            Name: "Delivery",
            Description:
                "It will be soon available. All what you need, in a meantime.",
            Brief: "Taste the best just on time!",
            IconUrl: "assets/img/services-details/delivery-icon.png",
        },
        {
            Id: 3,
            Name: "Recipes",
            Description:
                "Let your taste buds enjoy the flavor of the most delicious rural dishes, such as Lebanese Kishik, Mankousheh,  Batata Harra (Lebanese spicy potato) and many more. Surf the blog for the recipes.",
            Brief: "Add to your dining table simple, rural, Lebanese plates.",
            IconUrl: "assets/img/services-details/recipes.png",
        },
    ];

    static readonly FEATURES = [
        {
            Id: 1,
            Name: "Superb Oil",
            BackGroundUrl: "assets/img/home-three/superb-oil-background.jpg",
            IconUrl: "assets/img/home-three/superb-oil-icon.jpg",
            Description:
                "Simply, it’s the OLIVE OIL. A natural, rural oil obtained directly from the olive drupes passing through both manual mechanical process without adding any preservatives. Enjoy its leafy aroma, dark green color and intense taste. Try it, and you’ll never regret!",
        },
        {
            Id: 2,
            Name: "Frutti",
            BackGroundUrl: "assets/img/home-three/frutti-background.jpg",
            IconUrl: "assets/img/home-three/frutti-icon.jpg",
            Description: "",
        },
        {
            Id: 3,
            Name: "Healthy Snacks",
            BackGroundUrl: "assets/img/home-three/healthy-snack-background.jpg",
            IconUrl: "assets/img/home-three/healthy-snack-icon.jpg",
            Description: "",
        },

        {
            Id: 4,
            Name: "Herb’s Master",
            BackGroundUrl: "assets/img/home-three/herbs-master-background.jpg",
            IconUrl: "assets/img/home-three/herbs-master-icon.jpg",
            Description:
                "Za’atar is the master of a Lebanese pantry. It adds a magical touch to the breakfast! It is commonly mixed with olive oil and used as a topping for Mankousheh.",
        },

        {
            Id: 5,
            Name: "Cleansing Agent",
            BackGroundUrl:
                "assets/img/home-three/cleansing-agent-background.jpg",
            IconUrl: "assets/img/home-three/cleansing-agent-icon.jpg",
            Description:
                "An ecofriendly and 100% natural product produced from olive oil: Soap. Olive oil soap bars have multi-uses. Initial people used the olive oil soap as a shampoo and cleanser.",
        },
    ];

    static readonly Blog = [
        {
            Id: 1,
            Title: "Kafarحamam",
            Content:
                "Its name stands for “the home of peace”. It’s the source of mind-soothing and inspiration. We are granted to her as inspiring us for beginning our small business out of her bounties. Its soil is deeply rich by the long-living trees’ roots, such as olive and pine. They symbolize its strength toward the challenges it faced in the past. Its weather and nature are complementary. In winter, it is cold, snowy, bare, but full of riches including lemon, safarjal (cydonia oblonga) and many more. Waiting for the spring to emerge, buds start to bloom, and nature paints itself with the green color. The trees hold their offspring while others become ripened. Days pass, and summer returns! Enjoying the moments with its rich season that gives us a hand to serve you our products. Seasons can do more than changing the weather, it can gladly affect our services!",
            Brief: "Our homeland",
            ImageUrl: "assets/img/about/about-hero.jpg",
            Date: "April  19, 2023",
        },

        {
            Id: 2,
            Title: "Olive tree",
            Brief: "A long-life journey!",
            Content:
                "It belongs to one’s land. It’s the witness to every drop of sweat and tiresome accompanied with blessings. Olive tree is not only the symbol of peace but the source of birth and renewal, too. It produces olive fruit, olive oil, and soap. Even its waste is used as lubricant for the villagers to feel warm during the harsh winter- a multitask tree. Olive tree is an evergreen tree that beautifies our land with its sources and shadow.",
            ImageUrl: "assets/img/blog-details/zaytoun.jpg",
            Date: "January 2, 2023",
        },

        {
            Id: 3,
            Title: "Zaytoon",
            Brief: "It’s the olive tree’s leafy offspring.",
            Content:
                "How rich is your breakfast with an olive drupe dipped in labneh! What an acrid taste do olive drupes add to your pizza or pasta!Olive drupes add silvery green color to your dining table and nutritive substances to your health. Villagers wisely know what drupes should be taken to be pickled and served. They are different from the ones squeezed for olive oil.",
            ImageUrl: "assets/img/blog-details/olives.jpg",
            Date: "March 12, 2023",
        },

        {
            Id: 4,
            Title: "Lively Oil",
            Brief: "The Green Emerald",
            Content:
                "Before dawn, villagers head off to their land, well-prepared to start harvesting. They gently pick the OLIVES using a rake shape tool- this is an appropriate way to save the drupes from being squeezed- and they shake the rest from trees to fall onto nets. While some villagers work on the ladders, others pick the olives from the nets and put them inside bags. Then, they clean the olives by removing debris, such as twigs, leaves and stems using a manual slide. After many hours of hard-work, they took the crop to the olive mill where the olives are dragged into the olive squeezer. Here we begin! Here comes the precious product: OLIVE OIL. The squeezer presses the olives and crushes them into paste. This paste has a thick texture, so they add a little bit of water, for the oil will move into the machine to be filled in gallons. Note that the oil is not mixed with water. It is only a method used, so the water helps the oil flowing, and it is separated from the oil. That’s how the emerald olive oil is served to your table. Enjoy its color and taste, too!",
            ImageUrl: "assets/img/blog-details/oliveoil.png",
            Date: "February 15, 2023",
        },

        {
            Id: 5,
            Title: "Za’atar",
            Brief: "A Savory",
            Content:
                "Thyme is a kind of herb that has a satisfying smell and taste. It has different shapes besides several ways to be served, either as a spice or as a fine food. Villagers pick the thyme herb and drain it well. It should be dried on a piece of mesh with regular twisting. After that, they dry it under the sunlight, and they take it to the mill to be grinded. After that, they add roasted sesame, sumac and a pinch of salt. A jar of Za’atar completes your pantry and helps in giving ideas when you are hungry, such as Za’atar & Zeit (olive oil) sandwich, Mankousheh Za’atar & Zeit or za’atar rolls.",
            ImageUrl: "assets/img/blog-details/zaatarr.jpeg",
            Date: "April 8, 2023",
        },

        {
            Id: 6,
            Title: "Sumac ",
            Brief: "A Royal Crimson",
            Content: `Sumac is a reddish-hairy plant which symbolizes the bright autumn colors. It grows in  large clusters giving the drupes a fuzzy appearance. 
            It has a cultural significance in Lebanese cuisine. Sumac spice has a tart, lemony flavor and is often used as a seasoning for dishes like salads, kebbeh, meat, rice and more. It is also a key ingredient in the spice blend for our herb’s master, za'atar.
            -The process of obtaining the sumac spice is as follows:
            Sumac berries are harvested when they are ripe, usually in late summer or early autumn. After harvesting, the sumac berries are spread out in a well-ventilated area to dry in the sun. Once they are completely dried, they are separated by hand from the stems and any debris. The dried sumac berries are then ground into a coarse powder through mechanical grinders.. After grinding, the sumac powder is often sifted to remove any remaining coarse particles. The final ground sumac spice is packaged in containers to preserve its flavor and aroma.`,
            ImageUrl: "assets/img/blog-details/sumac.jpg",
            Date: "September 2, 2023",
        },

        {
            Id: 7,
            Title: "Figs",
            Brief: "A Natural Delight",
            Content: ` Growing a family of fig trees, one little offspring at a time. 
            Our dried figs are made from the finest, sun-ripened figs, carefully selected to ensure   the highest quality and natural sweetness. They are packed with essential vitamins, minerals, and dietary fiber. Our dried figs are a healthy snack choice that can boost your energy levels and support your overall well-being.`,
            ImageUrl: "assets/img/blog-details/figs.jpg",
            Date: "August 9, 2023",
        },
    ];

    //company email
    static readonly KAFARMAM_INFO_EMAIL = "info@kafarmam.com";
    static readonly KAFARMAM_NOREPLY_EMAIL = "no-reply@kafarmam.com";
    static readonly KAFARMAM_PHONE_NUMBER = "+96176019214";

    //links
    static readonly KAFARMAM_INSTAGRAM_LINK =
        "https://www.instagram.com/kafarmam.lb?utm_source=qr";
}