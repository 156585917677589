<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<section class="story-area pt-100 pb-70">
    <!-- <div class="story-shape"><img src="assets/img/about/story3.png" alt="Shape"></div> -->

    <div class="container">
        <!-- <div class="story-head">
            <h2>About Our Story</h2>
            <h5>A Lebanese local business established as an inclusive small business</h5>
            <h5>Enjoy everyday's food with a rural touch for nourishment and fresh flavor</h5>
            <p>Our business is an outcome of a cozy, rural gathering in our village, Kafarhamam, while eating a breakfast full of fresh, leafy food, such as olives, olive oil, labneh and za’atar. It all started as a joke!<br>“Why don’t we set up a marketing blog to sell these leafy products?” </p>
            <h4 style="margin-top: 30px;">Then we discussed some ideas, and we launched!</h4>

            <p>We have literally started working on our project since January 2, 2023 accompanied with several thoughts and planning. What special about our work is that each one of us has a specialty in running this business along with dedication and belief. We have worked in harmony to build this small business as a first step to show a part of our rural culture. All what your meal needs is found here. </p>
    
            <h4 style="margin-top: 30px;">Our aim is simple: Serving your appetite with a pure, rural taste.</h4>
        </div> -->
        <div>
           
            <div class="row about-first-cont ">
                <h2 class="about-title">About Our Story</h2>    
                <div class="col-sm-12 col-md-10 col-lg-6" >
   
    <h5 class="mt-5">A Lebanese local business established as an inclusive small business.</h5>
            <p>Our business is an outcome of a cozy, rural gathering in our village, Kafarhamam, while eating a breakfast full of
                fresh, leafy food, such as olives, olive oil, labneh and za’atar. It all started as a joke!<br><span>“Why don’t we set up
                a marketing blog to sell these leafy products?”</span> </p>


                </div>
          <div class="col-sm-12 col-md-10 col-lg-6" >
            <img src="assets/img/about/about1.jpg" alt="Download">
          </div>
            </div>
            <div class="row about-first-cont mt-5">
                 <div class="col-sm-12 col-md-10 col-lg-6">
                    <img src="assets/img/about/about2.jpg" alt="Download">
                </div>
                <div class="col-sm-12 col-md-10 col-lg-6">
                   
                    <h5 class="mt-5">Then we discussed some ideas, and we launched!</h5>
                    <p>We have literally started working on our project since January 2, 2023 accompanied with several thoughts and planning.
                    What special about our work is that each one of us has a specialty in running this business along with dedication and
                    belief. We have worked in harmony to build this small business as a first step to show a part of our rural culture. All
                    what your meal needs is found here. </p>
            
            
                </div>
               
            </div>

            <h4 style="margin-top: 30px;color: #808000;">Our aim is simple: Serving your appetite with a pure, rural taste.</h4>

        </div>

        <!-- <div class="row">
            <div class="col-sm-6 col-lg-6">
                <div class="story-item">
                    <img src="assets/img/about/story1.jpg" alt="Story">
                    <h3>Restaurant Service</h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="story-item">
                    <img src="assets/img/about/story2.jpg" alt="Story">
                    <h3>Home Delivery Service</h3>
                </div>
            </div>
        </div> -->
    </div>
</section>
<section class="about-us-two pt-100 pb-70">
    <div class="shape">
        <img src="assets/img/home-one/reservation-shape.png" alt="Download">
    </div>

    <div class="item">
        <h4>What does “<strong>KAFARMAM.</strong>” stand for?</h4>
        <p>It took us a lot of time to create a name for our products; it was uneasy! We agreed that the product’s name
            should represent goods we sell in a creative way. Because our products are rural and uprooted from our
            village’s sources, we have chosen “<strong>KAFARMAM.</strong>”.</p>
        <p>We simplified our village name, Kafarhamam, to become KAFARMAM. It represents our village in a unique and
            catchy way. Besides, some of its letters symbolize words that serve our products.</p>
        <h4 style="margin-top: 30px;"><strong>KAFARMAM.</strong></h4>
    </div>
</section>

<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Leafy Services</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="services-slider owl-theme owl-carousel">
            <div *ngFor="let service of services" class="services-item">
                <a (click)="GetServiceById(service.Id)">
                    <img src="{{service.IconUrl}}" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>{{service.Name}}</h3>
                    <p>{{service.Brief}}</p>
                </a>
            </div>

            <!-- <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services2.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Delivery</h3>
                    <p>Taste the best just on time!</p><br>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Recipes</h3>
                    <p>Add to your dining table simple, rural, Lebanese plates.</p>
                </a>
            </div> -->

            <!-- <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Discount Voucher</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div> -->
        </div>
    </div>
</section>



<!-- <section class="download-area-two pt-100 pb-70">
    <div class="download-shape">
        <img src="assets/img/home-one/reservation-shape.png" alt="Download">
        <img src="assets/img/about/download2.png" alt="Download">
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="download-item">
                    <h2>Download Our Mobile App That Make You More Easy to Order</h2>
                    <ul>
                        <li><a href="#"><img src="assets/img/home-two/google-store.png" alt="Google"></a></li>
                        <li><a href="#"><img src="assets/img/about/app.png" alt="App"></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/home-one/subscribe-main.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="chef-area chef-area-two pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Special Chefs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/1.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Doe</h3>
                            <span>Head of Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/6.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Smith</h3>
                            <span>Assistant Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item active">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/3.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Evanaa</h3>
                            <span>Intern Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/7.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Knot Doe</h3>
                            <span>Asst. Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->