import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastrNotificationService {

    constructor(
        private toastr: ToastrService
    ) {
    }

    ShowSuccess(message, title) {
        this.toastr.success(message, title, {
            positionClass: 'toast-bottom-right'
        })
    }

    ShowError(message, title) {
        this.toastr.error(message, title, {
            positionClass: 'toast-top-center'
        })
    }

    showInfo(message, title) {
        this.toastr.info(message, title)
    }

    showWarning(message, title) {
        this.toastr.warning(message, title)
    }
}
