<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more blog-details-more">
                        <h3>Recent Post</h3>

                        <ul *ngFor="let b of blogList">
                            <li><a (click)="GetBlogById(b.Id)" [ngStyle]="{ 'background-color': b.Id == blog.Id ? oliveColor : whiteColor,
                                                'color':b.Id == blog.Id? whiteColor:blackColor }">{{b.Title}}<i
                                        class='bx bx-plus'></i></a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="services-details-more blog-details-more">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog">Cooking <i class='bx bx-plus'></i></a></li>
                            <li><a routerLink="/blog">Street Food <i class='bx bx-plus'></i></a></li>
                            <li><a routerLink="/blog">Fast Food <i class='bx bx-plus'></i></a></li>
                        </ul>
                    </div>

                    <div class="blog-details-tags">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog">Pasta</a></li>
                            <li><a routerLink="/blog">Home Made</a></li>
                            <li><a routerLink="/blog">Food</a></li>
                            <li><a routerLink="/blog">Street</a></li>
                            <li><a routerLink="/blog">Yammy Food</a></li>
                        </ul>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-9">
                <div class="services-details-item">
                    <div class="services-details-fresh">
                        <h2>{{blog.Title}}</h2>
                        <p class="service-details-p">{{blog.Content}}</p>

                        <div *ngIf="blog.ImageUrl!=null">
                            <img src="{{blog.ImageUrl}}" alt="Blog">
                        </div>

                        <div class="blog-details-nav">
                            <ul>
                                <li><a (click)="GetPrevBlog(blog.Id)">Previous</a></li>
                                <li><a (click)="GetNextBlog(blog.Id)">Next</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>