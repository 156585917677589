import { Component, OnInit } from '@angular/core';
import { KafarmamConts } from 'src/assets/kafarmam-contants.consts';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  instaLink = KafarmamConts.KAFARMAM_INSTAGRAM_LINK;

}
