import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KafarmamConts } from '../../../../assets/kafarmam-contants.consts';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  constructor(
    private rout: ActivatedRoute,
  ) { }

  blog: any
  blogList = KafarmamConts.Blog;
  blogs = KafarmamConts.Blog;

  whiteColor = '#FFFFE9';
  blackColor = '#000000';
  oliveColor = '#808000';

  ngOnInit(): void {
    const id = +this.rout.snapshot.paramMap.get('id');
    this.blog = this.blogList.find((b) => b.Id == id)
    this.blogList = this.blogList.sort((a, b) => Date.parse(b.Date) - Date.parse(a.Date)).slice(0, 6)
  }

  GetBlogById(id) {
    this.blog = this.blogList.find((b) => b.Id == id)
  }

  GetNextBlog(id) {

    //get old blog index
    let currentBlogIndex = this.blogs.findIndex((b) => b.Id == id)

    //get next blog by index
    let nextBlog = this.blogs[currentBlogIndex + 1];

    //return blog0 if current is the last
    if (nextBlog == null) {
      this.blog = this.blogs[0]
    } else {
      this.blog = nextBlog
    }
  }

  GetPrevBlog(id) {

    //get old blog index
    let currentBlogIndex = this.blogs.findIndex((b) => b.Id == id)

    //get prev blog by index
    let prevBlog = this.blogs[currentBlogIndex - 1];

    //return blog[last] if current is the first
    if (prevBlog == null) {
      this.blog = this.blogs[this.blogs.length - 1]
    } else {
      this.blog = prevBlog
    }
  }

}
