
<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Categories</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Categories</li>
            </ul>
        </div>
    </div>
</div>

<div class="feature-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let feature of features">
                <div class="feature-item">
                    <img src="{{feature.BackGroundUrl}}" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src='{{feature.IconUrl}}' alt="Feature"></li>
                            <li><span>{{feature.Name}}</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
