import { Component, OnInit } from '@angular/core';
import { KafarmamConts } from '../../../../assets/kafarmam-contants.consts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private route: Router,
  ) { }

  ngOnInit(): void {
  }

  services = KafarmamConts.SERVICES;

  GetServiceById(id: number) {
    this.route.navigate(['/services-details', id]);
  }
}
