
<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-area services-area-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let service of services">
                <div class="services-item">
                    <a routerLink="/services-details/{{service.Id}}">
                        <img src="{{service.IconUrl}}" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>{{service.Name}}</h3>
                        <p>{{service.Brief}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
