import { Component, OnInit } from '@angular/core';
import { KafarmamConts } from '../../../../assets/kafarmam-contants.consts';
import { SubscriberInputDto } from '../../../../app/services/lambda/dtos/subscriber-input.dto';
import { NgForm } from '@angular/forms';
import { LambdaService } from '../../../../app/services/lambda/lambda.service';
import { ToastrNotificationService } from '../../../../app/services/toaster-notification/toastr-notification.service';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {

  constructor(
    private readonly lambdaService: LambdaService,
    private toastr: ToastrNotificationService
  ) { }

  features = KafarmamConts.FEATURES;
  services = KafarmamConts.SERVICES;
  instaLink = KafarmamConts.KAFARMAM_INSTAGRAM_LINK;

  blog: any
  subscriber = {} as SubscriberInputDto

  ngOnInit(): void {
    this.blog = KafarmamConts.Blog.filter((blog) => blog.ImageUrl != null)
      .sort((a, b) => Date.parse(b.Date) - Date.parse(a.Date)).slice(0, 3)
  }

  AddSubscriber(form: NgForm) {

    if(this.subscriber.Email == null){
      this.toastr.ShowError('Please enter your email', null)
      return
    }

    // add a subscriber into dynamo db
    this.lambdaService.AddSubscriber(<SubscriberInputDto>{
      Email: this.subscriber.Email,
    })
      .subscribe((response) => {
        this.resetform(form);
        this.toastr.ShowSuccess('Subscribed Successfully', null)
      })

  }

  resetform(form: NgForm) {
    form.form.reset();
  }

}
