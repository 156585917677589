import { Component, OnInit } from '@angular/core';
import { KafarmamConts } from 'src/assets/kafarmam-contants.consts';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  year = (new Date()).getFullYear();
  infoEmail = KafarmamConts.KAFARMAM_INFO_EMAIL;
  phoneNumber = KafarmamConts.KAFARMAM_PHONE_NUMBER;
  instaLink = KafarmamConts.KAFARMAM_INSTAGRAM_LINK;

}
