
<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="page-title-item">
                    <h2>Services Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li>Services Details</li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-7">
                <div class="page-title-plate">
                    <ul>
                        <li><img src="assets/img/services-details/food1.png" alt="Plate"></li>
                        <li><img src="assets/img/services-details/food2.png" alt="Plate"></li>
                        <li><img src="assets/img/services-details/food3.png" alt="Plate"></li>
                        <li><img src="assets/img/services-details/food4.png" alt="Plate"></li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <h3>More Services</h3>

                        <ul *ngFor = "let service of services">
                            <li><a (click)="GetById(service.Id)">{{service.Name}}<i class='bx bx-plus'></i></a></li>
                        </ul>
                    </div>

                    <!-- <div class="services-details-order">
                        <h3>Order Your Food Now</h3>
                        <span>09:00am - 12:00am</span>
                        <img src="assets/img/services-details/order.png" alt="Service">
                        <div class="offer-off">
                            <span>20%</span>
                            <span>OFF</span>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-9">
                <div class="services-details-item">
                    <div class="services-details-fresh">
                        <h2>{{service.Name}}</h2>
                        <p>{{service.Brief}}</p>
                        <img src="assets/img/services-details/2.jpg" alt="Service">
                        <p>{{service.Description}}</p>
                        <!-- <p class="services-details-p">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p> -->
                        <!-- <a class="cmn-btn" routerLink="/contact">Reservation</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section class="download-area-two pt-100 pb-70">
    <div class="download-shape">
        <img src="assets/img/home-one/reservation-shape.png" alt="Download">
        <img src="assets/img/about/download2.png" alt="Download">
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="download-item">
                    <h2>Download Our Mobile App That Make You More Easy to Order</h2>
                    <ul>
                        <li><a href="#"><img src="assets/img/home-two/google-store.png" alt="Google"></a></li>
                        <li><a href="#"><img src="assets/img/about/app.png" alt="App"></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/home-one/subscribe-main.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="chef-area chef-area-two pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Special Chefs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/1.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Doe</h3>
                            <span>Head of Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/6.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Smith</h3>
                            <span>Assistant Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item active">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/3.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Evanaa</h3>
                            <span>Intern Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/7.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Knot Doe</h3>
                            <span>Asst. Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
